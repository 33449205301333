function NotFound() {
  window.history.pushState(null, '', null);
  return (
    <div className="w-screen mx-auto">
      <div className="flex items-center justify-center min-h-screen bg-gray-200">
        <div className="bg-white rounded-lg shadow-lg max-w-sm w-full">
          <div className="bg-orange-500 rounded-t-lg p-8 text-center">
            <h2 className="text-white text-lg font-semibold">
              Uh-Oh. Whatever you're looking for doesn't seem to exist
            </h2>
          </div>
          <div className="p-8 text-center">
            <p className="text-gray-700 mb-6">
              Please contact your administrator
            </p>
            <p className="text-orange-501 mb-6"> </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
