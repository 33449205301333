import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { b64toBlob } from '../component/utils';
import { eSignApi } from '../api';
import { useGetSignedDocument } from '../queries/queries';

function DocumentDownload() {
    const params = useParams();
    const [esignRequestId, setEsignRequestId] = useState('');
    const [downloadUrl, setDownloadUrl] = useState('');
    const { data: fileData } = useGetSignedDocument()

    useEffect(() => {
        if(fileData){
            downloadPage(fileData.document, fileData.documentName);
        }
    }, [fileData])


    const downloadPage = (base64File: string, fileName: string) => {
        b64toBlob(
            base64File || '',
            'application/pdf',
            `${fileName}`,
        ).then(() => {
        });
    };
    const downloadLink = () => {
        downloadPage(fileData?.document || "", fileData?.documentName || "");
    }
    return (
        <div className="w-screen mx-auto">
            <div className="flex items-center justify-center min-h-screen bg-gray-200">
                <div className="bg-white rounded-lg shadow-lg max-w-sm w-full">
                    <div className="p-8 text-center">
                        <p className="text-gray-700 mb-6">
                            Your Document will get downloaded automatically.
                        </p>
                        <p className='text-xxs'>
                            If it doesn't start within a few moments, try using the link below.
                        </p>
                        <p className="text-orange-501 m-6">
                            <button
                                className=""
                                onClick={() => downloadLink()}
                            >
                                Download
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentDownload;