import { create, createStore } from 'zustand';
import { configurePersist } from 'zustand-persist';

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: 'root',
});

const persist = _persist as any;

interface Auth {
  isPreAuthenticated?: boolean;
  errorMessage?: string;
  accessToken?: string;
  emailId?: string;
}
interface AuthStore extends Auth {
  setPreAuth: (auth: Auth | ((auth: Auth) => Auth)) => void;
  clear: () => void;
}
export const usePreAuthorizedTokenStore = create<AuthStore>(
  persist(
    {
      key: 'authToken',
    },
    (set: any) => ({
      isPreAuthenticated: false,
      errorMessage: 'error',
      accessToken: undefined,
      user: undefined,
      setPreAuth: (auth: Auth | ((auth: Auth) => Auth)) => {
        if (typeof auth === 'function') {
          set((_state: Auth) => auth(_state));
        } else {
          set((_state: Auth) => auth);
        }
      },
      clear: () => {
        set((_state: any) => ({
          isPreAuthenticated: false,
          errorMessage: 'error',
          accessToken: undefined,
          user: undefined,
        }));
      },
    }),
  ),
);
