function Success() {
  window.history.pushState(null, '', null);
  return (
    <div className="w-screen mx-auto">
      <div className="flex items-center justify-center min-h-screen bg-gray-200">
        <div className="bg-white rounded-lg shadow-lg max-w-sm w-full">
          <div className="bg-green-500 rounded-t-lg p-8 text-center">
            <svg
              className="w-12 h-12 text-white mx-auto mb-4"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
            <h2 className="text-white text-lg font-semibold">SUCCESS</h2>
          </div>
          <div className="p-8 text-center">
            <p className="text-gray-700 mb-6">
              Congratulations, your document is signed.
            </p>
            <p className="text-orange-501 mb-6">
              {' '}
              You may close the window now.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Success;
