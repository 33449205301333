import React, { useEffect, useMemo, useState } from 'react';
import {
  useApproveDocument,
  useConfirmDocument,
  useGetDocForApproval,
} from '../queries/queries';
import {
  ButtonPrimary,
  HStack,
  SkeletonLoader,
  VStack,
} from '../component/utils';
import LazyPdfDocument from '../component/lazyPdfDocument';
import { Signer } from '../types/doc';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { Icon } from '@iconify/react';

interface SignerRowProps {
  signer: Signer;
}
function Approve() {
  const { data: dataForApproval, isFetching: isDocumentDetailsLoading } =
    useGetDocForApproval();
  const { mutate: approveDocument, isLoading: documentGettingApproved } =
    useApproveDocument();
  const { mutate: confirmDoc, isLoading: documentBeingConfirmed } =
    useConfirmDocument();
  const navigate = useNavigate();
  const [isDocumentApproved, setIsDocumentApproved] = useState(false);
  useEffect(() => {
    console.log(dataForApproval);
    if(dataForApproval && dataForApproval.esignStatus === 'completed'){
      navigate('/successApproved')
    }else if(dataForApproval && dataForApproval.esignStatus === 'cancelled'){
      navigate('/signCancelled')
    }
  }, [dataForApproval, navigate]);
  const signedPdf = useMemo(() => dataForApproval, [dataForApproval]);
  const approve = () => {
    if (isDocumentApproved) {
      confirmDoc(undefined, {
        onSuccess: (data) => {
          toast('Approval Confirmed', {autoClose: 2000, type: "success"});
          navigate('/success');
        },
        onError: () => {
          toast('Error while confirming', { autoClose: 2000, type: 'error' });
        },
      });
    } else {
      approveDocument(undefined, {
        onSuccess: (data) => {
          console.log(data);
          setIsDocumentApproved(true);
        },
        onError: () => {
          toast('Error while approving', { autoClose: 2000, type: 'error' });
        },
      });
    }
  };
  return (
    <div className="w-full ">
      <div className="flex h-[calc(100vh-15vh)] justify-center gap-16 mt-8">
        <VStack className="shadow-lg p-4 rounded-md bg-gray-400 relative overflow-auto w-3/5 overflow-y-auto">
        {isDocumentDetailsLoading ? (
          <SkeletonLoader className="h-[calc(100vh-20vh)] w-[calc(100vw-20vw)]" />
        ) : (
          <VStack className="border-b-4">
            <LazyPdfDocument
              url={signedPdf?.signedPdf || ''}
              key={'signedPdf'}
              name="Signed Document"
            />
          </VStack>
        )}
        </VStack>
      <div className="w-1/4 p-4 bg-gray-100 rounded-md flex flex-col overflow-scroll">
        <h2 className="text-lg font-semibold underline text-center">
          Signature Details
        </h2>
        {isDocumentDetailsLoading ? (
          <>
            <SkeletonLoader className="h-6 w-3/4 mt-2" />
            <hr />
            <SkeletonLoader className="h-6 w-3/4 mt-2" />
            <hr />
            <SkeletonLoader className="h-6 w-3/4 mt-2" />
            <hr />
            <SkeletonLoader className="h-6 w-3/4 mt-2" />
            <hr />
            <SkeletonLoader className="h-6 w-3/4 mt-2" />
            <hr />
          </>
        ) : (
          <VStack className=''>
            <div className="mt-2 justify-between text-sm flex">
              <span className="text-start flex-1">Document Name</span>
              <span className="text-blue-500 text-right">
                {signedPdf?.documentName}
              </span>
            </div>
            <hr />
            <div className="mt-2 justify-between text-sm flex">
              <span className="text-start flex-1">Reason</span>
              <span className="text-blue-500 text-right">
                {signedPdf?.reason}
              </span>
            </div>
            <hr />
            <div className="mt-2 justify-between text-sm flex">
              <span className="text-start flex-1">Status</span>
              <span className="text-blue-500 text-right">
                Signed (Awaiting Approval)
              </span>
            </div>
            <hr />
            <h3 className=" mt-4 font-semibold mb-2 underline text-center">
            Signatories
            </h3>
            <VStack className="min-h-80 max-h-72 overflow-auto py-4">
              {signedPdf?.signers?.map((signer, index) => {
                return <SignerRow signer={signer} key={index} />;
              })}
            </VStack>
          </VStack>
        )}
        <ButtonPrimary onClick={approve} className='mt-4 flex justify-center'>
          {documentBeingConfirmed ||
          isDocumentDetailsLoading ||
          documentGettingApproved ? (
            <Icon
              className="animate-spin text-center"
              icon="lucide:loader-2"
              width="24"
            />
          ) : isDocumentApproved ? (
            'Confirm'
          ) : (
            'Approve Document'
          )}
        </ButtonPrimary>
      </div>
      </div>
    </div>
  );
}

const SignerRow: React.FC<SignerRowProps> = ({ signer }) => {
  return (
    <div className="signer-row ps-2 m-2 flex items-center justify-between border-b-2">
      <div className="text-xxs w-24 break-words">
        <div className="font-bold">Name: {signer.name}</div>
        <div>Email: {signer.email}</div>
        <div>Sign Order: {signer.signPriority}</div>
        <div>Signed At: {new Date(signer.signedAt || '')?.toDateString()}</div>
        <div className='' >Status: {signer.signingStatus?.toUpperCase()}</div>
      </div>
      <div className="text-sm ">
        <img
          src={signer.signature}
          alt="Signature"
          style={{height: "100%", width: "100%", objectFit: "fill", zoom:"reset", aspectRatio:"auto"}}
          className="signature-image"
        />
      </div>
    </div>
  );
};
export default Approve;
