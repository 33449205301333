/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Icon, Dialog } from '@mui/material';
import { sign } from 'crypto';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import SignaturePad from 'signature_pad';
import LazyPdfDocument from '../component/lazyPdfDocument';
import { ProfileImage, ProfileDropDown } from '../component/Profile';
import {
  HStack,
  VStack,
  ButtonPrimary,
  ButtonPrimary1,
} from '../component/utils';
import useAuthStore from '../store/AuthStore';
import { useGetAdminUserDetails } from '../queries/queries';

function Layout() {
  const { user, setAuth } = useAuthStore();
  const { data: userData } = useGetAdminUserDetails();
  const [showHeader,setShowHeader] = useState(true)
  const location = window.location
  useEffect(()=>{
  if(location.ancestorOrigins.length>0){
    if(location.ancestorOrigins[0] !== location.origin){
      setShowHeader(false)
    }
  }
},[location])
  useEffect(() => {
    if (userData) {
      if (user?.type === 'signer' && userData.signingStatus === 'signed') {
        navigate('/success');
      }
    }
    setAuth({ user: { ...user, ...userData } });
  }, [userData]);
  const navigate = useNavigate();
  return (
    <div className="w-full h-calc(100vh-30vh) mx-auto">
      {(showHeader) && (
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 shadow-sm border-gray-200 sm:gap-x-6 bg-white px-4 sm:px-6 lg:px-8">
          <div className="flex flex-1 self-stretch justify-between items-center lg:gap-x-6">
            <div className="flex items-center gap-x-4 lg:gap-x-6 ">
              <img
                className="w-[56px] h-8"
                src="https://equion-dev.s3.us-west-2.amazonaws.com/logo_dark.svg"
                width={250}
                alt="hissa_logo"
              />
            </div>
            <div className="flex items-center gap-x-4 lg:gap-x-6">
              <HStack
                className="gap-2 text-xs cursor-pointer text-gray-dark"
                aria-label="navbar"
              >
                <div className="ml-3 mr-1">
                  <ProfileImage name={user?.full_name || 'User'} />
                </div>
                <div className="pt-2 mr-1">
                  <VStack className="w-28">
                    <p className="truncate">{user?.full_name}</p>
                    <HStack>
                      <span className="truncate">{user?.email_id}</span>
                      <ProfileDropDown navigate={navigate} />
                    </HStack>
                  </VStack>
                </div>
              </HStack>
            </div>
          </div>
        </div>
      )}
      <div className="flex-1 overflow-y-auto" id="outlet">
        <div className="relative w-screen ">
          <div className={`sticky top-0 z-20`}>{/* <MobileSideNav /> */}</div>
          <Box className="p-4 mx-auto max-w-screen-2xl">
            <Outlet />
          </Box>
        </div>
      </div>
    </div>
  );
}

export default Layout;
