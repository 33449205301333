import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import interact from 'interactjs';
import Tooltip from '@mui/material/Tooltip';

import { useGetDocumentsForPreview } from '../queries/queries';
import {
  ButtonPrimary,
  ButtonSecondary,
  convertToBase64,
  HStack,
  Input,
  Select,
  SkeletonLoader,
  VStack,
} from '../component/utils';
import useAuthStore from '../store/AuthStore';
import { eSignApi } from '../api';
import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';
import _, { set } from 'lodash';
import { Email } from '../types/requests';
import { useNavigate } from 'react-router';

interface DisplayEmailProps {
  details: Email;
  setEmails: React.Dispatch<React.SetStateAction<Email[]>>;
  index: number;
  sameFoAll: boolean;
  handleDelete: Function
}

function AdminRequestSignature() {
  const { data: docxPages, isLoading: isDocumentDetailsLoading, refetch: reloadDoc } =
    useGetDocumentsForPreview();
  const pages = useMemo(() => docxPages?.document, [docxPages]);
  const [emails, setEmails] = useState<Array<Email>>([]);
  const [allSignersAssigned, setAllSignersAssigned] = useState(false);
  const [wetSignatureData, setWetSignatureData] = useState<{ wetSignExists: boolean, wetSignatureTemplate: string }>();
  const [isSequential, setIsSequential] = useState(true);
  const [documentBeingSubmitted, setDocumentBeingSubmitted] = useState(false);
  const navigate = useNavigate();
  const addEmail = () => {
    const existingType = sameForAll ? emails[0].type : '';
    setEmails([...emails, { email: '', name: '', designation: '', type: existingType, isNew: true }]);
  };
  const [sameForAll, setSameForAll] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleToggleSameForAll = () => {
    setSameForAll(!sameForAll);
  };
  useEffect(() => {
    if (sameForAll) {
      const existingType = emails[0]?.type || '';
      const updatedEmails = emails.map((email, index) => {
        if (index > 0) {
          return { ...email, type: existingType };
        }
        return email;
      });
      setEmails(updatedEmails);
    }
  }, [sameForAll])
  useEffect(() => {
    const signers = docxPages?.signers;
    const emailsInRequest: Email[] = [];
    if (signers && signers.length > 0) {
      signers.forEach(email => {
        const type = email.signatureType === "wet_signature" ? "Wet Sign" : email.type === "esign" ? "E Sign" : email.type === "digio" ? "digio" : "";
        emailsInRequest.push({
          "companyName": email.companyName || '',
          "designation": email.designation === null ? "" : email.designation, "name": email.name,
          type: type, email: email.email,
          id: email.id,
          isEdited: false,
          isNew: false,
        })
      })
      setEmails(emailsInRequest);
    }
    else {
      setEmails([
        { email: '', name: '', designation: '', type: '', sendMail: false },
      ])
    }

  }, [docxPages])

  const dragMoveListener = (event: any) => {
    const target = event.target;
    const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
    const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

    target.style.transform = `translate(${x}px, ${y}px)`;
    target.setAttribute('data-x', x);
    target.setAttribute('data-y', y);
  };
  const validateEmails = () => {
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
    const isInvalid = emails.some(
      (email) => !emailRegex.test(email.email) || !email.name || !email.type,
    );
    const isDuplicate = emails.some((email, index) => {
      return emails.findIndex((e) => e.email === email.email) !== index;
    });
    let isDigioConfigValid = true;
    let isDigioRequested =
      emails.some((email) => email.type === 'Digio Signature')
    if (isDigioRequested) {
      isDigioConfigValid = emails.every((email) => email.type === 'Digio Signature');
    }
    return isInvalid || isDuplicate //|| isDigioConfigValid;
  };

  const resizeMoveListener = (event: any) => {
    const target = event.target;
    const x = parseFloat(target.getAttribute('data-x')) || 0;
    const y = parseFloat(target.getAttribute('data-y')) || 0;

    target.style.width = `${event.rect.width}px`;
    target.style.height = `${event.rect.height}px`;

    target.style.transform = `translate(${x}px, ${y}px)`;
  };
  const resetDoc = () => {
    setWetSignatureData({ wetSignExists: false, wetSignatureTemplate: "" })
    setAllSignersAssigned(false);
    reloadDoc();
  }

  const addDraggableBox = (email: Email, pageIndex: number, numPrevious: number) => {
    const signerColorPallete = ["#D8EFD3", "#95D2B3", "#55AD9B", "#F1F8E8"]
    const randomColor = signerColorPallete[numPrevious % 4];
    const box = document.createElement('div');
    box.id = `draggable-${btoa(email.name)}-${pageIndex}`
    box.innerHTML = `<p>${email.name}</p>`;
    box.classList.add('draggable-box');
    box.style.width = '210px';
    box.style.height = '60px';
    box.style.position = 'absolute';
    box.style.backgroundColor = randomColor;
    const container = document.getElementById(`page-container-${pageIndex}`);
    if (container) {
      const topOffsetToSubtract = numPrevious > 3 ? (Math.ceil(numPrevious / 3)) * 80 : 80;
      const leftOffsetToSubtract = numPrevious * 250;
      const top = `${(container.offsetTop + container.offsetHeight) - topOffsetToSubtract}px`;
      const left = `${(container.offsetLeft + container.offsetWidth) - leftOffsetToSubtract}px`;
      box.style.top = top;
      box.style.left = left;
      container?.appendChild(box);
      interact(box).draggable({
        allowFrom: '.draggable-box',
        inertia: true,
        modifiers: [
          interact.modifiers.restrict({
            restriction: container,
            elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
          }),
          interact.modifiers.restrictRect({
            restriction: `page-container-${pageIndex}`,
            endOnly: true,
          }),
        ],
        autoScroll: true,
        listeners: {
          move: dragMoveListener,
          end(event) {
            console.log(event)
            var textEl = event.target.querySelector('p');
            // textEl && (textEl.textContent = 'moved');
          },
        },
      });
    }

  };
  function calculateCoordinates(x: number, y: number, d: number) {
    const distance = Math.sqrt(x * x + y * y);

    const k = d / distance;

    const x1 = k * x;
    const y1 = k * y;

    return { x1: x1, y1: y1 };
  }

  const getCoordinates = (userId: string) => {
    const userDraggables = document.querySelectorAll(`div[id*="draggable-${btoa(userId)}"]`)
    const boxes = userDraggables;
    const coordinates: any = {}
    boxes.forEach((box, index) => {
      const parent = box.parentElement;
      const pageNo = parent?.id?.split('page-container-')[1] || "0";
      const signer = box.innerHTML.split('<p>')[1].split('</p>')[0];
      const parentRect = parent?.getBoundingClientRect();
      const rect = box.getBoundingClientRect();
      const boxBottomRelativeX = rect.left - (parentRect?.left || 0);
      const boxBottomRelativeY = Math.abs(rect.bottom - (parentRect?.bottom || 0));
      const diaoganal = Math.sqrt(Math.pow(210, 2) + Math.pow(60, 2))
      const { x1, y1 } = calculateCoordinates(boxBottomRelativeX, boxBottomRelativeY, diaoganal);
      console.log(x1, y1)

      const pdfPageHeight = (parentRect?.height || 1) * 72 / 96;
      console.log(pdfPageHeight, rect.bottom)

      const LLX = boxBottomRelativeX
      const LLY = boxBottomRelativeY
      const URX = x1
      const URY = y1
      coordinates[pageNo] = {
        llx: LLX,
        lly: LLY,
        urx: URX,
        ury: URY
      }
      console.log(
        `Box ${index}: Left Bottom X: ${rect.left}, Left Bottom Y: ${rect.bottom}, Right Top X: ${rect.right}, Right Top Y: ${rect.top}`,
        `Parent ${index} Left Bottom X: ${parentRect?.left}, Left Bottom Y: ${parentRect?.bottom}, Right Top X: ${parentRect?.right}, Right Top Y: ${parentRect?.top}`
      );
    });
    return coordinates
  };
  const submitDocument = () => {
    const emailBody = emails.map((email, index) => {
      return {
        email: email.email,
        name: email.name,
        signatureType:
          email.type === 'E Sign'
            ? 'esign'
            : email.type === 'Wet Sign'
              ? 'wet_signature'
              : 'digio',
        designation: email.designation,
        signPriority: index + 1,
        coordinates: getCoordinates(email.name),
        sendMail: email.sendMail,
        isEdited: email.isEdited,
        id: email.id,
        companyName: email.companyName || ""
      };
    });
    let requestBody = { signSequentially: isSequential, signers: emailBody, wetSignatureTemplate: wetSignatureData?.wetSignatureTemplate };
    if (wetSignatureData?.wetSignExists && !wetSignatureData.wetSignatureTemplate) {
      toast("No Custom template uploaded for Wet Signature", { type: "warning", "autoClose": 2000 });
      delete requestBody.wetSignatureTemplate;
    }
    setDocumentBeingSubmitted(true);
    eSignApi
      .post('/admin/addSigners', requestBody)
      .then((response) => {
        console.log(response);
        eSignApi
          .post('/admin/submitDocument', requestBody)
          .then((response) => {
            setDocumentBeingSubmitted(false);
            toast(
              'Document submitted successfully, you can now close the window',
              { autoClose: 2000, type: 'success' },
            );
            navigate('/successSubmission')
          })
          .catch(() => {
            toast(`Error while adding Signatories`)
          });
      })
      .catch(() => toast(`Error while adding Signatories`));
  };
  const addSignerBoxes = () => {
    if (validateEmails()) {
      toast('Please check the Signatory details entered', {
        autoClose: 2000,
        type: 'error',
      });
      return;
    }
    let wetSignatureExists = false;
    emails.forEach((email, index) => {
      let numPrevious = emails.slice(0, index).filter((e) => e.type !== 'Wet Sign').length;
      if (['E Sign', 'Digio Signature'].includes(email.type)) {
        pages?.forEach((page, pageIndex) => {
          addDraggableBox(email, pageIndex, numPrevious + 1);
        });
      }
      else if (email.type === 'Wet Sign') {
        wetSignatureExists = true;
      }
    });
    setAllSignersAssigned(true);
    setWetSignatureData({ wetSignatureTemplate: "", wetSignExists: wetSignatureExists })
  };

  function handleFileChange(event: ChangeEvent<HTMLInputElement>): void {
    const file = event?.target?.files?.[0];
    if (!file) {
      toast('No file uploaded', { autoClose: 2000, type: "error" });
      return;
    }
    else {
      if (!(file.name.endsWith('.docx') || file.name.endsWith('.doc'))) {
        setDisableSubmit(true)
        event.target.files = null
        toast('Only Docx file supported', { autoClose: 2000, type: "error" });
        return;
      }
      setDisableSubmit(false)
      convertToBase64(file).then((data) => {
        setWetSignatureData({ wetSignExists: true, wetSignatureTemplate: data as string });
      })
    }
  }
  const handleDeleteSignerDetails = (indexNumb: number) => {
    const newEmails = [...emails];
    if (indexNumb >= 0 && indexNumb < newEmails.length) {
      newEmails.splice(indexNumb, 1);
    }
    setEmails(newEmails);
    setEmails(newEmails)
  }
  return (
    <div
      className={`flex h-[calc(100vh-15vh)] justify-center`}
    >      <div className="shadow-lg rounded-md bg-gray-400 relative overflow-auto w-1/3 m-2 max-h-[100vh-15vh]">
        {isDocumentDetailsLoading ? (
          <SkeletonLoader className="h-[calc(100vh-20vh)] w-[calc(100vw-20vw)]" />
        ) : (
          <VStack className="border-b-4 ">
            {pages?.map((page, index) => {
              return (
                <HStack
                  id={`page-container-${index}`}
                  key={index}
                  className="p-2"
                >
                  <img
                    src={page.image}
                    id={`page-${index}`}
                    alt=""
                    key={page.page}
                  />
                </HStack>
              );
            })}
          </VStack>
        )}
      </div>
      <div className="w-1/2 p-4 bg-gray-100 rounded-md flex flex-col m-2 overflow-scroll">
        <h2 className="text-lg font-semibold mb-2 underline text-center overflow-hidden min-h-7">
          Request Signatories:
        </h2>
        <hr />
        <HStack className='justify-between'>
          <HStack className='items-center justify-items-center gap-2 py-2'>
            <Tooltip
              className="inline-flex text-sm text-orange-501 font-light align-top mb-2 "
              title="When checked, signature type of first Signatory gets applied to all the Signatories"
            >
              <Icon icon="material-symbols:info-outline" style={{ margin: 0 }} />
            </Tooltip>{' '}
            <label className="font-semibold">Same for All Signatories</label>
            <input
              type="checkbox"
              checked={sameForAll}
              onChange={handleToggleSameForAll}
              className="form-checkbox bg-orange-501 accent-orange-501"
            />
          </HStack>
          <ButtonPrimary
            onClick={addEmail}
            className='my-2 px-2'
            disabled={documentBeingSubmitted || allSignersAssigned}
          >
            <Icon icon="material-symbols:add" width="15" height="15" />
          </ButtonPrimary>
        </HStack>
        <hr />
        <VStack className="min-h-40 max-h-96 overflow-y-auto">
          {emails.map((email, index) => {
            return (
              <DisplayEmail
                key={email.id}
                details={email}
                setEmails={setEmails}
                index={index}
                sameFoAll={sameForAll}
                handleDelete={(indexNumber: number) => {
                  handleDeleteSignerDetails(indexNumber)
                }}
              />
            );
          })}
        </VStack>
        <hr />
        <div className="flex items-center gap-2 my-4">
          <Tooltip
            className="inline-flex text-sm text-orange-501 font-light align-top mb-2 "
            title="When checked, signature requests will be sent one after the other."
          >
            <Icon icon="material-symbols:info-outline" style={{ margin: 0 }} />
          </Tooltip>
          <label className="text-sm">Sequential Signing ?</label>
          <input
            type="checkbox"
            checked={isSequential}
            onChange={() => setIsSequential(!isSequential)}
            className="form-checkbox bg-orange-501 accent-orange-501"
          />
        </div>
        {wetSignatureData?.wetSignExists &&
          <VStack>
            <HStack className='gap-4 ps-0 p-2'>
              <label className='text-sm px-6'>Wet Signatory Template :</label>
              <a className='text-sm text-blue-500 underline' href={docxPages?.wetSignatureTemplate} target='blank'> Default Template</a>
            </HStack>
            <label className='text-sm justify-center flex'>(Or)</label>
            <HStack className='gap-2 ps-0 p-2'>
              <Tooltip
                className="inline-flex text-sm text-orange-501 font-light align-top mt-1"
                title="This will replace the Default template with Custom template."
              >
                <Icon icon="material-symbols:info-outline" className='' />
              </Tooltip>
              <label className='text-sm'>Upload Custom Wet Signatory Template ?</label>
            </HStack>
            <Input
              type='file'
              accept='.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              onChange={handleFileChange}
            />
          </VStack>
        }

        <HStack className="justify-end gap-4 mt-4 bottom-0">
          <ButtonSecondary onClick={() => resetDoc()} disabled={isDocumentDetailsLoading}>Reset</ButtonSecondary>
          {allSignersAssigned ? (
            <ButtonPrimary
              disabled={isDocumentDetailsLoading || disableSubmit}
              onClick={submitDocument}
              className="overflow-hidden flex justify-center"
            >
              {documentBeingSubmitted ? (
                <Icon
                  className="animate-spin text-center"
                  icon="lucide:loader-2"
                  width="24"
                />
              ) : (
                'Submit Document'
              )}
            </ButtonPrimary>
          ) : (
            <>
              <ButtonPrimary
                onClick={addSignerBoxes}
                className="overflow-hidden"
                disabled={documentBeingSubmitted || isDocumentDetailsLoading}
              >
                Next
              </ButtonPrimary>
            </>
          )}
        </HStack>
      </div>
    </div>
  );
}

const DisplayEmail: React.FC<DisplayEmailProps> = ({
  details,
  setEmails,
  index,
  sameFoAll,
  handleDelete
}) => {
  const [emailDetails, setEmailDetails] = useState(details);
  useEffect(() => {
    setEmailDetails(details)
  }, [details])
  const handleChange = (field: string, value: string | boolean) => {
    const updatedDetails = { ...emailDetails, [field]: value };
    updatedDetails.isEdited = true;
    setEmailDetails(updatedDetails);
    setEmails((prevEmails) => {
      const newEmails = [...prevEmails];
      if (sameFoAll && field === 'type') {
        newEmails.forEach((email) => {
          email.type = value.toString();
        });
        return newEmails;
      }
      newEmails[index] = updatedDetails;
      return newEmails;
    });
  };

  return (
    <HStack className="w-auto py-2 overflow-none [&:not(:last-child)]:border-b [&:not(:last-child)]:border-dotted [&:not(:last-child)]:border-black-501 rounded-none">
      <div className="flex-shrink-0 flex flex-col items-center justify-around me-2">
        <span>{index + 1}</span>
        <Icon icon="clarity:avatar-line" className="text-md" />
      </div>
      <VStack className="w-full">
        <HStack className="w-full gap-2">
          <VStack className="w-3/6">
            <Input
              type="text"
              className="form-input text-xs"
              placeholder="Enter Name"
              name='username'
              autoComplete='username'
              required
              value={emailDetails.name}
              onChange={(e) => handleChange('name', e.target.value)}
            />
          </VStack>
          <VStack className="w-3/6">
            <Input
              type="email"
              className="form-input"
              name='email'
              autoComplete='email'
              placeholder="Enter Email"
              required
              value={emailDetails.email}
              onChange={(e) => handleChange('email', e.target.value)}
            />
          </VStack>
        </HStack>
        <HStack className="w-full gap-2 mt-2">
          <VStack className="w-3/6">
            <Input
              type="designation"
              className="form-input"
              name='designation'
              autoComplete='designation'
              placeholder="Enter Designation"
              required
              value={emailDetails.designation}
              onChange={(e) => handleChange('designation', e.target.value)}
            />
          </VStack>
          <VStack className="w-3/6">
            <Select
              className="p-3 rounded-lg text-sm text-gray-500"
              placeholder="Select Signature Type"
              disabled={index !== 0 && sameFoAll}
              required
              options={['Wet Sign']}
              value={emailDetails.type}
              onChange={(e) => {
                handleChange('type', e.target.value);
              }}
            />
          </VStack>
        </HStack>
        <HStack className="w-full gap-2 mt-2">
          <VStack className="w-3/6">
            <Input
              type="text"
              className="form-input"
              name='companyName'
              autoComplete='companyName'
              placeholder="Enter Company Name"
              required
              value={emailDetails.companyName}
              onChange={(e) => handleChange('companyName', e.target.value)}
            />
          </VStack>
        </HStack>
      </VStack>
      <VStack className='justify-center ms-2'>
        <ButtonPrimary className='my-2 px-2' onClick={() => { handleDelete(index) }} disabled={index > 0 ? false : true}>
          <Icon icon="material-symbols:delete-outline" height='15' width='15' />
        </ButtonPrimary>
      </VStack>
      {/* <VStack className="w-24 justify-center align-middle justify-self-auto ps-2">
            <HStack className="justify-around items-center">
              <Tooltip
                className="text-sm text-blue-500 font-light align-top"
                title="When checked, user will be notified for every signature on the document."
              >
                <Icon icon="material-symbols:info-outline" style={{margin:0}} />
              </Tooltip>
              <label className="text-xxs inline-flex">Send status</label>
            </HStack>

            <input
              type="checkbox"
              checked={emailDetails.sendMail}
              onChange={(e) => handleChange('sendMail', e.target.checked)}
              className="form-checkbox h-5 w-5 text-blue-600 mx-auto justify-center align-middle mt-2"
            />
          </VStack> */}
    </HStack>
  );
};
export default AdminRequestSignature;
