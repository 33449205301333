import * as React from 'react';

import { useEffect } from 'react';
import useAuthStore from '../store/AuthStore';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
type SkeletonLoaderProps = {
  className?: string;
};
/*
Wrapper components around regular divs to improve code readability
with nested divs
*/

export function ViewStack(
  props: React.HTMLAttributes<HTMLElement>,
): React.ReactElement {
  const className = `flex flex-col w-[900px] ${props.className}`;
  return <div {...props} className={className}></div>;
}
export function HStack(
  props: React.HTMLAttributes<HTMLDivElement>,
): React.ReactElement {
  const className = `flex flex-row ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function VStack(
  props: React.HTMLAttributes<HTMLDivElement>,
): React.ReactElement {
  const className = `flex flex-col ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function Center(
  props: React.HTMLAttributes<HTMLDivElement>,
): React.ReactElement {
  const className = `flex items-center justify-center ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function Box(
  props: React.HTMLAttributes<HTMLDivElement>,
): React.ReactElement {
  const className = `${props.className}`;
  return <div {...props} className={className}></div>;
}

export function ButtonPrimary(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>,
) {
  const { disabled, className, type, ...rest } = props;
  const _type = type || 'button';
  const _className = `px-4 py-2 font-semibold text-white rounded-lg text-[13px] leading-4 bg-orange-501 focus:opacity-90 hover:bg-orange-500 
    ${
      disabled && 'cursor-not-allowed bg-zinc-300 hover:bg-zinc-300'
    } ${className} cursor-pointer `;
  return (
    <button
      disabled={disabled}
      className={_className}
      type={_type}
      {...rest}
    ></button>
  );
}

export function ButtonPrimary1(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>,
): React.ReactElement {
  const { className, type, ...rest } = props;
  const _type = type || 'button';
  const _className = `px-4 py-3 bg-white rounded-lg text-[13px] text-[#e85936] leading-4 font-semibold focus:opacity-90 hover:bg-slate-200  ${className}`;
  return <button {...rest} className={_className} type={_type}></button>;
}

export function ButtonSecondary1(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>,
): React.ReactElement {
  const { className, type, ...rest } = props;
  const _type = type || 'button';
  const _className = `px-4 py-2 bg-white rounded-lg h-[38px] text-[13px] leading-4 text-[#e85936] font-medium focus:opacity-90 hover:bg-slate-200 border border-solid ${className}`;
  return <button {...rest} className={_className} type={_type}></button>;
}

export function ButtonSecondary(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>,
): React.ReactElement {
  const { className, type, ...rest } = props;
  const _type = type || 'button';
  const _className = `px-4 py-2 font-semibold rounded-lg text-[13px] text-gray-dark bg-gray-light focus:opacity-90 hover:bg-gray-400 ${className}`;
  return <button {...rest} className={_className} type={_type}></button>;
}

export function ButtonCancelSecondary(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>,
): React.ReactElement {
  const { className, type, ...rest } = props;
  const _type = type || 'button';
  const _className = `p-2 font-normal rounded h-8 text-gray-dark text-sm1 bg-gray-light focus:opacity-90 hover:bg-gray-100 ${className}`;
  return <button {...rest} className={_className} type={_type}></button>;
}

export function Error({ text }: { text?: string }) {
  return <p className="text-[9px] text-red-500 my-0.5">{text}</p>;
}
export type SelectInputProps<T> = {
  options: T[];
  isPlaceHolderDisabled?: boolean;
  placeholder?: string;
  valueGetter?: (option: T) => any;
  textGetter?: (option: T) => string;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

export function Select<T>(props: SelectInputProps<T>) {
  const {
    options,
    value,
    isPlaceHolderDisabled,
    placeholder,
    valueGetter: _valueGetter,
    textGetter: _textGetter,
    className: _className,
    ...rest
  } = props;
  const defaultGetter = (option: any) => option?.toString();
  const valueGetter = _valueGetter || defaultGetter;
  const textGetter = _textGetter || defaultGetter;
  return (
    <select
      className={` form-select ${_className} ${
        props.disabled ? 'cursor-not-allowed' : ''
      }`}
      value={value}
      {...rest}
    >
      <option disabled={isPlaceHolderDisabled} value={''}>
        {!placeholder ? '--Select--' : placeholder}
      </option>
      {options.map((option, i) => (
        <option key={i} value={valueGetter(option)}>
          {textGetter(option)}
        </option>
      ))}
    </select>
  );
}

function getAccessToken(): string {
  const token = localStorage.getItem('accesstoken') as string;
  return token;
}
export function redirectToHissa(path: string, postLogout = false) {
  const hissaUrl =
    process.env.REACT_APP_LEGACY_URL ||
    localStorage.getItem('hissaUrl') ||
    'https://dev.hissa.com';
  if (postLogout) {
    window.location.href = hissaUrl;
    return;
  }
  window.location.href = `${hissaUrl}/${path}?accessToken=${getAccessToken()}&companyId=${localStorage.getItem(
    'companyId',
  )}`;
}

export function clearCredentials() {
  const credentialKeys = [
    'accesstoken',
    'hissaUrl',
    'companyId',
    'x-usr-token',
  ];
  credentialKeys.forEach((key) => {
    localStorage.removeItem(key);
  });
}

export function stringFormat(str: string) {
  return str.replaceAll('_', ' ');
}
type CurrencySymbolFormatterType = {
  returnFormat: string;
  num?: any;
  currencyType: string;
};

export function Input(props: React.InputHTMLAttributes<HTMLInputElement>) {
  const { className, placeholder, ...rest } = props;
  const _className = `${className} form-input `;
  return (
    <input
      {...rest}
      className={_className}
      placeholder={placeholder}
      onWheel={(e) => e.currentTarget.blur()}
      autoComplete="off"
    ></input>
  );
}
export function InputField(props: React.InputHTMLAttributes<HTMLInputElement>) {
  const { className: _className, placeholder, ...rest } = props;
  const className = `${_className} form-input border`;
  return (
    <input
      {...rest}
      className={className}
      placeholder={placeholder}
      onWheel={(e) => e.currentTarget.blur()}
      autoComplete="off"
    ></input>
  );
}
type InputWithTextProps = {
  optionsAvailable?: number;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
  className,
}) => <div className={`animate-pulse bg-gray-300 rounded ${className}`}></div>;

export function HandleRedirect() {
  const { setAuth } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    const params = window.location.search;
    if (params) {
      const urlSearchParams = new URLSearchParams(params);
      const accesstoken = urlSearchParams.get('logintoken');
      console.log("got params", accesstoken);
      const redirectPath = urlSearchParams.get('path') || '/';
      if (accesstoken) {
        localStorage.setItem('accesstoken', accesstoken);
        const accessToken = accesstoken?.split('Bearer ')[1];
        const decodedToken = jwtDecode(accessToken) as any;
        console.log('tiudshfuidhfudhfudfh', decodedToken);
        if (decodedToken?.type === 'admin' || decodedToken?.type === 'EQUION') {
          console.log('IAM ADMIN', decodedToken);
          setAuth({
            isAuthenticated: true,
            accessToken: accesstoken || '',
            user: decodedToken,
          });
        } else {
          setAuth({
            isAuthenticated: false,
            accessToken: accesstoken || '',
            user: decodedToken,
          });
        }

        const currentURL = window.location.href;
        const urlObj = new URL(currentURL);
        const domain = urlObj.origin;
        const path = urlObj.pathname;

        const newURL = domain + path;
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, '', newURL);
        setTimeout(() => {
          console.log(path);
          navigate(path, { replace: true });
        }, 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
export const b64toBlob = (base64: string, type = 'application/octet-stream', fileName = 'download') =>
  fetch(`data:${type};base64,${base64}`).then(res => res.blob()).then(blob => {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  })
export const convertToBase64 = (file: File, toResolveasDataUrl = false) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(
        toResolveasDataUrl
          ? fileReader?.result?.toString()
          : fileReader?.result
              ?.toString()
              .replace('data:', '')
              .replace(/^.+,/, ''),
      );
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
