import React, { Suspense } from 'react';
import PdfDocument from './pdfDocument';

export type PDFDocumentComponentPropTypes = {
  url: string;
  name?: string;
};

const LazyPdfDocument: React.FC<PDFDocumentComponentPropTypes> = ({
  url,
  name,
}) => {
  //   const { isMobile } = useIsMobile();
  return (
    <div
      className={` bg-white rounded-lg w-full
        overflow-auto`}
    >
      <Suspense fallback={<div>Document loading...</div>}>
        <PdfDocument url={url} name={name} />
      </Suspense>
    </div>
  );
};

export default LazyPdfDocument;
