import { create, createStore } from 'zustand';
import { configurePersist } from 'zustand-persist';
import { User } from '../types/user';

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: 'root',
});

const persist = _persist as any;

type UserDetails = {
  id: string;
  userId: string;
  emailId: string;
  userName: string;
  email: string;
};

interface Auth {
  isAuthenticated?: boolean;
  isAuthenticating?: boolean;
  errorMessage?: string;
  accessToken?: string;
  user?: User;
}
interface AuthStore extends Auth {
  setAuth: (auth: Auth | ((auth: Auth) => Auth)) => void;
  clear: () => void;
}
const useAuthStore = create<AuthStore>(
  persist(
    {
      key: 'preAuthToken',
    },
    (set: any) => ({
      isAuthenticated: false,
      isAuthenticating: false,
      errorMessage: 'error',
      accessToken: undefined,
      isIframe: 'false',
      user: undefined,
      setAuth: (auth: Auth | ((auth: Auth) => Auth)) => {
        if (typeof auth === 'function') {
          set((_state: Auth) => auth(_state));
        } else {
          set((_state: Auth) => auth);
        }
      },
      clear: () => {
        set((_state: any) => ({
          isAuthenticated: false,
          isAuthenticating: false,
          errorMessage: 'error',
          isIframe: 'false',
          accessToken: undefined,
          user: undefined,
        }));
      },
    }),
  ),
);

export default useAuthStore;
