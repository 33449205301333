/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { FileUploader } from 'react-drag-drop-files';
import html2canvas from 'html2canvas';
import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  InputField,
  VStack,
  convertToBase64,
} from './utils';
import { useSignaturePadDialog } from '../store/SingatureStore';

export type SignatureProps = {
  onClose: () => void;
  ids?: string[];
  type?: string;
};
interface GeneratedSign {
  name: string;
  style: {
    fontFamily: string;
    color: string;
    textTransform: string;
    fontSize: string;
  };
}
export const SignaturePad = () => {
  const { state: showSignaturePad, setState: setShowSignaturePad } =
    useSignaturePadDialog();
  const [mode, setMode] = useState('');
  const canvasRef = useRef<SignatureCanvas>(null);
  const [uploadedImage, setUploadedImage] = useState('');
  const [generatedSignature, setGeneratedSignature] = useState('');
  const handleClose = () => {
    setShowSignaturePad({ ...showSignaturePad, open: false });
  };
  const handleSave = () => {
    if (mode === 'draw') {
      if (canvasRef?.current?.isEmpty() && uploadedImage === '') {
        setShowSignaturePad({
          open: true,
          signature: '',
          signatureType: 'error',
        });
        toast('Draw/upload your signature', {
          autoClose: 2000,
          type: 'warning',
        });
        return;
      }
      if (!canvasRef?.current?.isEmpty() && uploadedImage !== '') {
        toast('Please use either one of Signature pad or Drag - N - Drop');
        return;
      }
      if (uploadedImage !== '') {
        setShowSignaturePad({
          ...showSignaturePad,
          signature: uploadedImage,
          signatureType: 'regular',
          open: false,
        });
      }
      if (!canvasRef?.current?.isEmpty()) {
        setShowSignaturePad({
          ...showSignaturePad,
          signature: canvasRef?.current?.getTrimmedCanvas().toDataURL() || '',
          signatureType: 'regular',
          open: false,
        });
      }
    } else if (generatedSignature !== '') {
      setShowSignaturePad({
        ...showSignaturePad,
        signature: generatedSignature,
        signatureType: 'regular',
        open: false,
      });
    }
  };
  function getUploadedImage(signature: string) {
    setUploadedImage(signature);
  }
  return (
    <Box className="h-1/2 w-200 ">
      <div className="m-4 justify-between flex">
        <span>Signatory Window</span>
        <span onClick={() => handleClose()} className="cursor-pointer">
          X
        </span>
      </div>
      <hr />
      {mode === '' && (
        <>
          <HStack className="h-full mt-4 justify-evenly">
            <VStack>
              <Box className="w-full h-60">
                <Box
                  onClick={() => setMode('draw')}
                  className="w-56 h-56 p-4 text-center align-middle cursor-pointer hover:border-orange-501 hover:border-4 hover:border-solid text-slate-501 justify-middle"
                >
                  <div className="p-4 text-base underline">Draw Signature</div>
                  <div className="flex justify-center">
                    <Icon
                      className="cursor-pointer text-orange-501"
                      icon="material-symbols:draw"
                      width={36}
                    />
                  </div>
                  <div className="mt-2">
                    Go artistic. Use your mouse or trackpad to make a statement.
                  </div>
                </Box>
              </Box>
            </VStack>
            <VStack>
              <Box className="w-full h-60">
                <Box
                  onClick={() => setMode('type')}
                  className="w-56 h-56 p-4 text-center align-middle cursor-pointer hover:border-orange-501 hover:border-4 hover:border-solid text-slate-501 justify-middle"
                >
                  <div className="p-4 text-base underline">Type Signature</div>
                  <div className="flex justify-center">
                    <Icon
                      className="cursor-pointer text-orange-501"
                      icon="tabler:keyboard"
                      width={36}
                    />
                  </div>
                  <div>
                    Type your name and choose the font that best matches your
                    personality.
                  </div>
                </Box>
              </Box>
            </VStack>
          </HStack>
        </>
      )}
      {mode === 'draw' && (
        <HStack className="gap-2 px-4 ">
          <VStack>
            <HStack className="justify-center pt-10">
              Draw your Signature Here
            </HStack>
            <HStack className="border-2 border-solid bg-slate-300">
              <SignatureCanvas
                penColor="black"
                ref={canvasRef}
                canvasProps={{
                  width: '300px',
                  height: '200px',
                  className: 'bg-slate-300',
                }}
                backgroundColor="rgb(255, 255, 255)"
              />
            </HStack>
          </VStack>
          <VStack className="">
            <HStack className="justify-center pt-10">
              Upload your Signature Here
            </HStack>
            <HStack className="mt-6">
              <DragDrop onImageUpload={getUploadedImage} />
            </HStack>
            {uploadedImage && (
              <HStack className="items-center px-4 mx-auto mt-6 border border-solid rounded-md">
                <img
                  src={`data:image/png;base64,${uploadedImage}`}
                  alt=""
                  width="300px"
                  height="200px"
                  id="signatureImage"
                />
              </HStack>
            )}
          </VStack>
        </HStack>
      )}
      {mode === 'type' && (
        <HStack className="w-full px-4">
          <SignatureGenerator selectedSignaure={setGeneratedSignature} />
        </HStack>
      )}
      {mode !== '' && (
        <HStack className="justify-end">
          <ButtonPrimary
            className="m-2"
            onClick={() => {
              handleSave();
            }}
          >
            {!showSignaturePad.isLoading ? (
              `Save`
            ) : (
              <Icon
                className="animate-spin"
                icon="lucide:loader-2"
                width={24}
              />
            )}
          </ButtonPrimary>
          {mode !== 'type' && (
            <ButtonSecondary
              className="m-2"
              onClick={() => canvasRef?.current?.clear()}
            >
              Clear
            </ButtonSecondary>
          )}
        </HStack>
      )}
    </Box>
  );
};

function DragDrop(props: { onImageUpload: (sign: string) => void }) {
  const { onImageUpload } = props;
  const [file, setFile] = useState('');
  const fileTypes = ['JPG', 'JPEG', 'PNG'];
  const isValidImageFileUploaded = (file: File) => {
    const validExtensions = ['png', 'jpeg', 'jpg'];
    const fileExtension = file.type.split('/')[1];
    if (!validExtensions.includes(fileExtension)) {
      return false;
    }
    if (file.size / 1024 > 50) {
      toast(
        'File size should be less than 50kb, please upload a different file',
        {
          type: 'error',
        },
      );
      return false;
    }
    return true;
  };
  const handleChange = async (file: any) => {
    if (file && file.length > 0) {
      const fileToCheck = file[0];
      if (!isValidImageFileUploaded(fileToCheck)) {
        return;
      }
      const img = new Image();
      img.src = URL.createObjectURL(fileToCheck);
      img.onload = async () => {
        const width = img.width;
        const height = img.height;
        if (width > 240 || height > 120) {
          toast(
            'File height and width should be less than 120px and 240px, please upload a different file',
            {
              type: 'error',
            },
          );
        } else {
          convertToBase64(file[0]).then((data: any) => {
            setFile(data as string);
            onImageUpload(data as unknown as string);
          });
        }
      };
    }
  };
  return (
    <FileUploader
      handleChange={handleChange}
      multiple="false"
      name="file"
      types={fileTypes}
    />
  );
}

const SignatureGenerator = (props: { selectedSignaure: any }) => {
  const [name, setName] = useState('');
  const [signatures, setSignatures] = useState<Array<GeneratedSign>>([]);
  const [selectedSignature, setSelectedSignature] = useState('');
  const { state: showSignaturePad, setState: setShowSignaturePad } =
    useSignaturePadDialog();

  const onSignatureSelect = (signtaure: string) => {
    setSelectedSignature(signtaure);
    props.selectedSignaure(signtaure);
  };
  const generateSignatureStyles = () => {
    const generatedSignatures = [];

    for (let i = 0; i < 10; i++) {
      const style = {
        fontFamily: [
          'cursive',
          'Great Vibes',
          'Nothing You Could Do',
          'serif',
          'Marck Script',
        ][Math.floor(Math.random() * 5)],
        color: ['black', 'blue'][Math.floor(Math.random() * 2)],
        textTransform: ['uppercase', 'lowercase', 'capitalize'][
          Math.floor(Math.random() * 3)
        ],
        fontSize: '18px',
      };
      generatedSignatures.push({ name, style });
    }

    setSignatures(generatedSignatures);
  };

  return (
    <HStack className="w-full min-w-[600px] h-64 mt-8 mb-4 justify-evenly">
      <VStack className="">
        <HStack className=" pb-2">
          <InputField
            type="text"
            placeholder="Enter Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </HStack>
        <HStack className="mt-4 pb-2 border-b border-dashed">
          <ButtonPrimary onClick={generateSignatureStyles}>
            {signatures.length > 0 ? 'Generate More' : 'Generate Signatures'}
          </ButtonPrimary>
        </HStack>
        {selectedSignature && (
          <HStack className="mt-8">
            <table>
              <tbody>
                <tr className="max-w-12 overflow-auto">
                  {selectedSignature && (
                    <>
                      <div className="text-xs">Selected Signature: </div>
                      <div
                        style={{
                          maxHeight: '200px',
                          maxWidth: '300px',
                          overflow: 'hidden',
                        }}
                        className="border"
                      >
                        <img
                          style={{
                            maxHeight: '150px',
                            aspectRatio: '3/4',
                            maxWidth: '300px',
                          }}
                          className="max-h-full max-w-full"
                          height="100"
                          src={selectedSignature}
                          alt="Selected Signature"
                        />
                      </div>
                    </>
                  )}
                </tr>
              </tbody>
            </table>
          </HStack>
        )}
      </VStack>
      <VStack className="w-1/2 overflow-y-auto">
        <div>
          <h2 className="text-lg font-semibold mb-4">
            Generated Signatures{' '}
            <span className="text-xs font-normal align-middle">
              (choose one)
            </span>
            :
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
            {signatures.length > 0 &&
              signatures.map((signature, index) => (
                <div
                  key={index}
                  className="flex justify-center items-center border rounded-md"
                >
                  <TextToImage
                    onSignatureSelect={onSignatureSelect}
                    style={signature.style}
                    text={signature.name}
                  />
                </div>
              ))}
          </div>
        </div>
      </VStack>
    </HStack>
  );
};
const TextToImage = (props: {
  text: string;
  style: any;
  onSignatureSelect: any;
}) => {
  const { text, style, onSignatureSelect } = props;
  const imageRef = useRef<any>();

  const convertToImage = () => {
    imageRef.current.style.backgroundColor = 'transparent';
    html2canvas(imageRef.current as HTMLElement).then((canvas) => {
      // Convert the canvas to a data URL
      const image = canvas.toDataURL();
      onSignatureSelect(image);
    });
  };

  return (
    <div
      onClick={convertToImage}
      className="p-4 m-4 cursor-pointer min-h-fit"
      ref={imageRef}
      style={style}
    >
      {text}
    </div>
  );
};

export default SignaturePad;
