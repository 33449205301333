/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import useAuthStore from '../../store/AuthStore';
import { useLocation, useNavigate } from 'react-router';
import { Icon } from '@iconify/react';
import { VStack, HStack, ButtonPrimary, Input } from '../../component/utils';
import useIsMobile from '../../component/detectDevice';
import { useDoLogin } from '../../queries/queries';
import { toast } from 'react-toastify';

const AdminLogin = () => {
  const { setAuth } = useAuthStore();
  const [password, setPassword] = useState('');
  const [email, setEmailId] = useState('');
  const [hideShowPassword, setHideShowPassword] = useState(false);
  const { mutate: login, isLoading } = useDoLogin();
  const navigate = useNavigate();

  function doLogin(e: any) {
    if (!email || !password) {
      toast('Email and Password is mandatory', {
        autoClose: 2000,
        type: 'error',
      });
      return;
    }
    const encryptedPassword = btoa(`${password}::hissabyrulezero`);
    const loginObj = { emailId: email, password: encryptedPassword };
    login(loginObj, {
      onSuccess: (data) => {
        console.log(data);
        setAuth({
          isAuthenticated: true,
          user: data.user,
          accessToken: data.token,
        });
        setTimeout(() => {
          navigate('/admin/dashboard');
        }, 1000);
      },
      onError: (err: any) => {
        if (err?.response?.data?.message === 'Document already signed') {
          navigate('/success');
        } else toast('Login Failed', { type: 'error' });
      },
    });
  }
  const { isMobile } = useIsMobile();
  return (
    <VStack className="justify-between h-screen text-sm bg-black-301 olderPhones:text-xs">
      <VStack className="gap-12 my-auto top-1/2">
        <VStack
          className={`items-center justify-center h-auto gap-8 p-4 m-auto bg-white rounded-lg shadow-worth ${
            isMobile ? 'w-96' : 'w-128'
          } olderPhones:max-w-80 min-h-96`}
        >
          <VStack className="gap-8">
            <HStack>
              <VStack className="gap-8 mx-auto">
                <HStack className="justify-center">
                  <img
                    src="https://equion-dev.s3.us-west-2.amazonaws.com/logo_dark.svg"
                    className='w-1/2'
                    width={250}
                    alt=""
                  />
                </HStack>
                <HStack className="justify-center text-md font-semibold text-[#000] olderPhones:text-sm">
                  Log in to your account
                </HStack>
              </VStack>
            </HStack>
          </VStack>
          <VStack className="w-full gap-8 px-12 mx-auto">
            <>
              <VStack className="w-full gap-0">
                <Input
                  type="email"
                  className="w-full border border-solid"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => setEmailId(e.target.value)}
                />
              </VStack>
            </>
            <>
              <HStack className="items-center bg-white border border-solid rounded outline-none text-slate-dark">
                <input
                  type={`${!hideShowPassword ? 'password' : 'text'}`}
                  className="form-input"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Icon
                  icon={`${
                    hideShowPassword ? 'mingcute:eye-2-line' : 'tabler:eye-off'
                  }`}
                  width="24"
                  className="mr-4 text-slate-501"
                  onClick={() => setHideShowPassword(!hideShowPassword)}
                />
              </HStack>
            </>

            <ButtonPrimary
              disabled={!email || !password}
              className="flex justify-center"
              onClick={(e) => doLogin(e)}
            >
              {isLoading ? (
                <Icon
                  className="animate-spin text-center"
                  icon="lucide:loader-2"
                  width="24"
                />
              ) : (
                'Login'
              )}
            </ButtonPrimary>
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default AdminLogin;
