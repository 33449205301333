import React, { useMemo, useState } from 'react';
import { useGetAllAdminRequests } from '../../queries/queries';
import {
  Box,
  HStack,
  SkeletonLoader,
  VStack,
} from '../../component/utils';
import { Icon } from '@iconify/react';
import { EsignRequest } from '../../types/requests';
import LazyPdfDocument from '../../component/lazyPdfDocument';
import useAuthStore from '../../store/AuthStore';
import { Doughnut } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import 'chart.js/auto';
import { FileUploader } from 'react-drag-drop-files';
import { Signer } from '../../types/doc';

function AdminDashboard() {
  const { data: adminRequests, isFetching: requestsBeingFetched } =
    useGetAllAdminRequests();
  const statistics = useMemo(() => {
    const total = adminRequests?.length;
    const pending = adminRequests?.filter(
      (request) => request.status === 'pending' || request.status === 'signature_requested',
    ).length;
    const signed = adminRequests?.filter(
      (request) =>
        request.status === 'signed' || request.status === 'completed',
    ).length;
    const draft = adminRequests?.filter(
      (request) => request.status === 'draft',
    ).length;
    const statistics = {
      total,
      pending,
      signed,
      draft,
    };
    return statistics;
  }, [adminRequests]);
  const dataForPieChart = useMemo(() => {
    let total = 0;
    const signTypes = {
      esign: 0,
      wet: 0,
      digio: 0,
    };
    adminRequests?.forEach((request) => {
      request.signers.forEach((signer) => {
        total += 1;
        if (signer.signatureType === 'esign') signTypes.esign += 1;
        if (signer.signatureType === 'wet_signature') signTypes.wet += 1;
        if (signer.signatureType === 'digio') signTypes.digio += 1;
      });
    });
    const data: ChartData<'doughnut'> = {
      datasets: [
        {
          data: [signTypes.esign, signTypes.wet, signTypes.digio],
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        },
      ],
      labels: ['E-Signature', 'Wet Signature', 'Digio'],
    };
    console.log(data);
    return data;
  }, [adminRequests]);
  const { user } = useAuthStore();
  const dummy = [1, 2, 3, 4, 5];
  return (
    <div className="w-3/4 mx-auto">
      {requestsBeingFetched && (
        <SkeletonLoader className="h-[calc(100vh-20vh)] w-[calc(100vw-40vw)]" />
      )}
      {!requestsBeingFetched && (
        <VStack className="flex mx-auto max-h-svh justify-center gap-16 mt-8">
          <h1 className="text-2xl">
            Welcome <span className="text-orange-501">{user?.full_name}</span>,
          </h1>
          <HStack className="w-full justify-evenly align-middle p-4 h-80 gap-8">
            <VStack className=" p-4 w-1/4 justify-center align-middle text-center bg-white rounded-md">
              <Doughnut
                data={dataForPieChart}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      title: {
                        display: false,
                        text: 'Signature Types',
                        color: 'black',
                        position: 'end',
                      },
                      display: true,
                      fullSize: true,
                      maxHeight: 100,
                      position: 'bottom',
                      labels: {
                        color: 'black',
                      },
                    },
                  },
                }}
                redraw
              />
            </VStack>
            <VStack className="w-1/4 justify-between text-center align-middle  gap-2 bg-white rounded-md p-4">
              <p className="text-9xl justify-between align-middle">
                {statistics.total}
              </p>
              <p>Total Requests</p>
              <HStack className="text-center justify-evenly">
                <p className="text-xs">Pending: {statistics.pending}</p>
                <p className="text-xs">Completed: {statistics.signed}</p>
                <p className="text-xs">Draft: {statistics.draft}</p>
              </HStack>
            </VStack>
            <VStack className="w-2/4 bg-white rounded-md align-middle justify-between text-center">
              <Box className="my-auto mx-auto">
                <FileUploader
                  className="w-full h-52"
                  label="Upload a document"
                  fileTypes={['pdf']}
                  onDrop={(file: any) => {
                    console.log(file);
                  }}
                />
              </Box>
            </VStack>
          </HStack>
          {requestsBeingFetched ? (
            <>
              {dummy.map((_, index) => (
                <SkeletonLoader key={index} />
              ))}
            </>
          ) : (
            <>
              <VStack className="p-4 shadow-md overflow-y-auto bg-white rounded-md min-h-96">
                <HStack className="text-lg font-medium text-headerColor whitespace-nowrap p-4 mb-8">
                  Your Requests
                </HStack>
                {adminRequests?.map((request) => (
                  <DocumentRequestRow
                    key={request.id}
                    request={request}
                    shouldExpand={adminRequests?.length === 1}
                  />
                ))}
              </VStack>
            </>
          )}
        </VStack>
      )}
    </div>
  );
}
const getBgColor = (status: string) => {
  switch (status) {
    case 'pending':
    case 'signature_requested':
      return 'bg-yellow-400';
    case 'signed':
      return 'bg-green-400';
    case 'draft':
      return 'bg-blue-400';
    case 'completed':
      return 'bg-green-400';
    default:
      return 'bg-gray-400';
  }
};
const DocumentRequestRow = ({
  request,
  shouldExpand,
}: {
  request: EsignRequest;
  shouldExpand: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(shouldExpand || false);

  const toggleRow = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="mb-4">
      <div
        className={`w-full ${getBgColor(request.status)} py-4 px-8 rounded-lg shadow-md cursor-pointer flex justify-between items-center`}
        onClick={toggleRow}
      >
        <HStack className="w-full justify-between">
          <VStack>
            <h1 className="font-semibold">{request.documents.documentName}</h1>
            <p className="text-gray-500">
              {request.requestedBy} - {request.createdAt}
            </p>
          </VStack>
          <VStack className="">
            <StatusPill status={request.status} />
          </VStack>
        </HStack>
        <div>
          {isExpanded ? (
            <Icon icon="mdi:chevron-up" className="w-6 h-6" />
          ) : (
            <Icon icon="mdi:chevron-down" className="w-6 h-6" />
          )}
        </div>
      </div>
      {isExpanded && (
        <div className="mt-2 p-4 bg-white border rounded-lg shadow-inner transition-all duration-300 ease-in-out justify-center">
          <h2 className="text-lg font-semibold">Request Details</h2>
          <hr />
          <HStack className="gap-4">
            <p>Type: {request.documents.documentType}</p>
            <p>Reason: {request.reason}</p>
          </HStack>
          <hr />
          <HStack className="gap-8 m-4 justify-end">
            <VStack className="max-h-96 min-w-96 rounded-md">
              <LazyPdfDocument
                url={request.pdfUrl}
                key={request.id}
                name="Signed Document"
              />
              <Icon icon="line-md:download-loop" height={24} width={24}></Icon>
            </VStack>
            <VStack className="w-3/4">
              {request.signers.map((signer) => (
                <SignerCard key={signer.email} signers={signer} />
              ))}
            </VStack>
            <VStack className="w-1/3">
              <SignerActions signer={request.signers[0]} />
            </VStack>
          </HStack>
        </div>
      )}
    </div>
  );
};

const StatusPill = ({ status }: { status: string }) => {
  const textColor = getBgColor(status);
  const statusToDisplay = status.split('_').join(' ').toUpperCase();
  const complementaryColors: { [key: string]: string } = {
    'bg-yellow-400': 'text-purple-600',
    'bg-green-400': 'text-red-600',
    'bg-blue-400': 'text-orange-600',
    'bg-gray-400': 'text-black',
  };

  const getComplementaryColor = (bgColor: string) => {
    return complementaryColors[bgColor] || 'text-gray-700';
  };

  return (
    <div
      className={` rounded-lg ${textColor} ${getComplementaryColor(textColor)} flex flex-row py-3`}
    >
      {statusToDisplay}
    </div>
  );
};

const SignerCard = ({ signers }: { signers: Signer }) => {
  const statusToDisplay = signers.signingStatus
    .split('_')
    .join(' ')
    .toUpperCase();
  const signatureType = signers.signatureType
    .split('_')
    .join(' ')
    .toUpperCase();
  const getSignerCardColor = (status: string) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-400';
      case 'signed':
        return 'bg-green-400';
      case 'draft':
        return 'bg-blue-400';
      case 'completed':
        return 'bg-green-400';
      default:
        return 'bg-gray-400';
    }
  };
  return (
    <HStack className="w-full m-2">
      <VStack
        className={`w-full ${getSignerCardColor(signers.signingStatus)}  h-32 rounded-lg shadow-md p-4 bg-opacity-50`}
      >
        <HStack className="gap-2">
          <p className="font-semibold">{signers.name}</p>
          <p className="text-gray-500">{signers.email}</p>
        </HStack>
        <HStack>
          <p className="text-gray-500"> Signature Status: {statusToDisplay}</p>
        </HStack>
        <p className="text-gray-500">Signature Type: {signatureType}</p>
        {signers.signingStatus === 'pending' && (
          <p className="text-xs text-blue-500 cursor-pointer">Send reminder</p>
        )}
        {signers.signingStatus === 'signed' && (
          <p>
            Signed At: {new Date(signers.signedAt).toLocaleDateString()}
          </p>
        )}
      </VStack>
    </HStack>
  );
};

const SignerActions = ({ signer }: { signer: Signer }) => {
  return (
    <VStack className='text-xs mt-4 text-blue-400 h-44 ms-8 text-center'>
      <p className="hover:underline cursor-pointer">Download document</p>
      <hr />
      <p className="hover:underline cursor-pointer">Download Audit Trial</p>
      <hr />
      <p className="hover:underline"></p>
    </VStack>
    
  );
};
export default AdminDashboard;
