import React, { useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import Dashboard from './pages/dashboard/Dashboard';
import { PrivateRoutes } from './PrivateRoutes';
import Success from './pages/Sucess';
import NotFound from './pages/NotFound';
import AdminLogin from './pages/login/AdminLogin';
import AdminRequestSignature from './pages/AdminRequestSignature';

import useAuthStore from './store/AuthStore';
import Layout from './layout/Index';
import Login from './pages/login/Login';
import Approve from './pages/Approve';
import AdminDashboard from './pages/dashboard/AdminDashboard';
import SuccessSubmission from './pages/SucessSubmission';
import DocumentDownload from './pages/DocumentDownload';
import SuccessApproved from './pages/SuccessApproved';
import SignCancelled from './pages/SignCancelled';

const Router = () => {
  const { isAuthenticated: routeAuthenticated, user } = useAuthStore();
  const userType = useMemo(() => {
   let userType = user?.type;
   if (userType?.toLowerCase() === 'equion') {
      userType = 'admin';
    }
    return userType;
  }, [user]);
  return (
    <Routes>
      <Route
      key="adminLogin"
        path="/adminLogin"
        element={
          routeAuthenticated ? (
            <Navigate
              to="/admin/dashboard"
              replace
              state={{ search: window.location.search }}
            />
          ) : (
            <AdminLogin />
          )
        }
      />
      <Route
        path="/signerLogin"
        key={"signerLogin"}
        element={
          routeAuthenticated ? (
            <Navigate
              to="/signer/dashboard"
              replace
              state={{ search: window.location.search }}
            />
          ) : (
            <Login />
          )
        }
      />
      <Route path="/success" key="success" element={<Success />} />
      <Route path="/successApproved" key="success" element={<SuccessApproved />} />
      <Route path="/successSubmission" key="success" element={<SuccessSubmission />} />
      <Route path="/signCancelled" key="cancelled" element={<SignCancelled />} />
      <Route
        path="/dashboard"
        element={
          <PrivateRoutes key="Dashboard" isAuthenticated={routeAuthenticated || false}>
            <Dashboard />
          </PrivateRoutes>
        }
      />
      <Route
        path="/admin"
        element={
          <PrivateRoutes key="AdminLayout" isAuthenticated={routeAuthenticated || false}>
            <Layout />
          </PrivateRoutes>
        }
        children={[
          <Route
            path="requestSign"
            element={
              <PrivateRoutes
              key={"requestSign"}
                isAuthenticated={
                  (routeAuthenticated || false)
                }
              >
                <AdminRequestSignature />
              </PrivateRoutes>
            }
          />,
          <Route
          path="dashboard"
          element={
            routeAuthenticated && userType === 'admin' ? <Navigate to="/adminLogin" />:
            <PrivateRoutes key="adminDashboard" isAuthenticated={routeAuthenticated || false}>
              <AdminDashboard />
            </PrivateRoutes>
          }
        />,
          <Route
            path="approve"
            element={
              <PrivateRoutes key="AdminApprove" isAuthenticated={routeAuthenticated || false}>
                <Approve />
              </PrivateRoutes>
            }
          />,
        ]}
      />
      <Route
        path="/signer"
        element={
          <PrivateRoutes
            key="SignerLayout"
            isAuthenticated={
              (routeAuthenticated || false) && userType === 'signer'
            }
          >
            <Layout />
          </PrivateRoutes>
        }
        children={[
          <Route
            path="dashboard"
            element={
              <PrivateRoutes key="signerDashboard" isAuthenticated={routeAuthenticated || false}>
                <Dashboard />
              </PrivateRoutes>
            }
          />,
        ]}
      />
      <Route
        path="/"
        element={
          <Navigate
            to={
              routeAuthenticated
                ? userType === 'admin'
                  ? '/admin/dashboard'
                  : '/signer/dashboard'
                : '/adminLogin'
            }
            replace
            state={{ search: window.location.search }}
          />
        }
      />
      <Route
        path='/document/:id'
        key={"DocumentView"}
        element={
          routeAuthenticated ?
            <PrivateRoutes isAuthenticated={routeAuthenticated || false}>
              <DocumentDownload key={'DocumentDownload'} />
            </PrivateRoutes> : <NotFound />
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default Router;
