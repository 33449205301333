import axios, { AxiosError } from 'axios';
import { LoginData } from './types/user';
import useAuthStore from './store/AuthStore';
import { ApproveDoc, NewDocMetaData } from './types/doc';
import { Email, EsignRequest, SignedDocument } from './types/requests';
export interface DocMetaData {
  documentUrl?: string;
  signRequestedBy?: string;
  signRequestedOn?: string;
  fileName?: string;
  signatureReason?: string;
  status?: string;
}

export const eSignApi = axios.create({
  baseURL: 'https://esign-api.hissa.com',
  headers: { 'Content-Type': 'application/json' },
});
eSignApi.interceptors.request.use((config) => {
  const accessToken = useAuthStore.getState().accessToken;
  config.headers.Authorization = accessToken;
  return config;
});
eSignApi.interceptors.response.use(
  (res) => {
    if (res.status === 401) {
      useAuthStore.setState((store) => ({ ...store, isAuthenticated: false, user: undefined}));
      return Promise.reject(res);
    }
    return res;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      useAuthStore.getState().clear();
    }
    return Promise.reject(error);
  }
);

export const doLogin = async (loginObj: {
  emailId: string;
  password: string;
}): Promise<LoginData> => {
  const res = await eSignApi.post('/auth/admin/login', loginObj);
  return res.data.data;
};

export const getUnsignedDocument = async (): Promise<NewDocMetaData> => {
  const res = await eSignApi.get('/signer/getDocument');
  return res.data.data;
};

export const insertSignature = async (
  signature: string,
): Promise<{ message: string }> => {
  return eSignApi
    .post('/signer/signDocument', { signature })
    .then((data) => data.data.data);
};

export const getAdminUserDetails = async () => {
  return eSignApi.get('/auth/user').then((data) => data.data.data);
};

export const getSignerUserDetails = async () => {
  return eSignApi.get('/auth/user').then((data) => data.data.data);
};

export const markDocumentSigned = async (signature: string) => {
  return eSignApi
    .post('/signer/completeSigning', { signature })
    .then((res) => res.data);
};

export const getDocForPreview = async (): Promise<{
  document: Array<{ image: string; page: number }>;
  documentName: string;
  documentType: string;
  createdAt: string;
  wetSignatureTemplate?:string;
  signers?:Array<Email>;
}> => {
  return eSignApi.get('/admin/getDocument').then((res) => res.data.data);
};

export const sendOTP = async (email: string) => {
  return eSignApi.post('/auth/login', { email }).then((res) => res.data.data);
};

export const verifyOtp = async (otp: string) => {
  return eSignApi
    .post('/auth/verify_otp', { otp })
    .then((res) => res.data.data);
};

export const getDocForApproval = async (): Promise<ApproveDoc> => {
  return eSignApi
    .get('/admin/getDocumentForApproving')
    .then((res) => res.data.data);
};

export const approveDocument = async () => {
  return eSignApi.post('/admin/approveDocument').then((res) => res.data.data);
};
export const confirmDocument = async () => {
  return eSignApi.post('/admin/confirmApprove').then((res) => res.data.data);
};

export const getAllAdminRequests = async (): Promise<EsignRequest[]> => {
  return eSignApi.get('/admin/getAllRequests').then((res) => res.data.data);
};

export const getSignedDocument = async (): Promise<SignedDocument> => {
  return eSignApi.get('/document/getDocument').then((res) => res.data.data);
};