/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import useAuthStore from '../../store/AuthStore';
import { usePreAuthorizedTokenStore } from '../../store/PreAuthrizedTokenStore';
import { jwtDecode } from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router';
import { Icon } from '@iconify/react';
import { VStack, HStack, ButtonPrimary, Input } from '../../component/utils';
import useIsMobile from '../../component/detectDevice';
import { useDoLogin, useSendOTP, useVerifyOtp } from '../../queries/queries';
import { toast } from 'react-toastify';

const Login = () => {
  const { setAuth, user, isAuthenticated } = useAuthStore();
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [email, setEmailId] = useState('');
  const [hideShowPassword, setHideShowPassword] = useState(false);
  const userType = useMemo(() => user?.type, [user]);
  // const { mutate: login, isLoading } = useDoLogin();
  const { mutate: sendTempPassword, isLoading: isSendingOtp } = useSendOTP();
  const { mutate: verifyUserOtp } = useVerifyOtp();
  const [resendTimer, setResendTimer] = useState(0);
  const navigate = useNavigate();
  const startResendTimer = () => {
    setResendTimer(60);
    const interval = setInterval(() => {
      setResendTimer((prev) => prev - 1);
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
    }, 60000);
  }
  useEffect(() => {
    if (!userType) {
      toast('Please use the link sent to your email to login', {
        autoClose: 2000,
        type: 'error',
      });
    }
  }, [userType]);

  function sendOtp() {
    if(resendTimer > 1) return;
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
    if(!emailRegex.test(email)){
      toast('Error! you have entered invalid email.', { autoClose: 2000, type: 'error' })
    }else{
    sendTempPassword(email, {
      onSuccess: (data) => {
        toast('OTP sent successfully', { autoClose: 2000, type: 'success' });
        startResendTimer();
        setOtpSent(true);
      },
      onError: (e: any) => {
        const error = e?.response?.data?.data?.message || 'Failed to verify OTP'
        toast(error, { autoClose: 2000, type: 'error' });
      },
    });
  }
  }
  function verifyOtp() {
    verifyUserOtp(password, {
      onSuccess: (data) => {
        setAuth({ isAuthenticated: true });
        setTimeout(() => {
          navigate('/signer/dashboard');
        }, 0);
      },
      onError: (err: any) => {
        const error = err?.response?.data?.data?.message || 'Failed to verify OTP'
        toast(error, { autoClose: 2000, type: 'error' });
      },
    });
  }
  function process() {
    if (userType === 'signer') {
      if (otpSent) {
        verifyOtp();
      } else {
        sendOtp();
      }
    }
  }
  const { isMobile } = useIsMobile();
  return (
    <VStack className="justify-between h-screen text-sm bg-black-301 olderPhones:text-xs">
      <VStack className="gap-12 my-auto top-1/2">
        <VStack
          className={`items-center justify-center h-auto gap-8 p-4 m-auto bg-white rounded-lg shadow-worth ${
            isMobile ? 'w-96' : 'w-128'
          } olderPhones:max-w-80 min-h-96`}
        >
          <VStack className="gap-8">
            <HStack>
              <VStack className="gap-8 mx-auto">
                <HStack className="justify-center">
                  <img
                    src="https://equion-dev.s3.us-west-2.amazonaws.com/logo_dark.svg"
                    className='w-1/2'
                    width={250}
                    alt=""
                  />
                </HStack>
              </VStack>
            </HStack>
          </VStack>
          <VStack className="w-full gap-8 px-12 mx-auto">
            <>
              <VStack className="w-full gap-0">
                <Input
                  type="email"
                  className="w-full border border-solid"
                  placeholder="Enter Email"
                  readOnly={
                    userType === 'admin' || (userType === 'signer' && otpSent)
                      ? true
                      : false
                  }
                  value={email}
                  onChange={(e) => setEmailId(e.target.value)}
                />
              </VStack>
            </>
            <>
              {userType === 'admin' ||
                (userType === 'signer' && otpSent && (
                  <>
                    <HStack className="items-center bg-white border border-solid rounded outline-none text-slate-dark">
                      <input
                        type={`${!hideShowPassword ? 'password' : 'text'}`}
                        className="form-input"
                        placeholder="Enter OTP"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <Icon
                        icon={`${
                          hideShowPassword
                            ? 'mingcute:eye-2-line'
                            : 'tabler:eye-off'
                        }`}
                        width="24"
                        className="mr-4 text-slate-501 cursor-pointer"
                        onClick={() => setHideShowPassword(!hideShowPassword)}
                      />

                      <VStack className="border-l-2 border-solid  justify-between text-center pl-2">
                        <Icon
                          icon={'material-symbols:refresh'}
                          width="24"
                          className={`mr-4 text-slate-501  ${resendTimer > 1 ? 'cursor-not-allowed' : 'cursor-pointer hover:animate-spin hover:text-blue-400'} `}
                          onClick={() => sendOtp()}
                        />
                      </VStack>
                    </HStack>
                    { resendTimer > 1 && <p className='text-end text-xxs -my-6 ' >Resend in <span className='text-blue-400 animate-pulse'>{resendTimer}s</span></p>}
                  </>
                ))}
            </>

            <ButtonPrimary
              disabled={userType === 'admin' ? !email || !password : !email}
              className="flex justify-center"
              onClick={() => process()}
            >
              {isSendingOtp ? (
                <Icon
                  className="animate-spin text-center"
                  icon="lucide:loader-2"
                  width="24"
                />
              ) : userType === 'admin' ? (
                'Login'
              ) : otpSent ? (
                'Login'
              ) : (
                'Send OTP'
              )}
            </ButtonPrimary>
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default Login;
