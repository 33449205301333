import React from 'react';
import './App.css';
import Router from './Router';
import { queryClient } from './queries/Client';
import { QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HandleRedirect } from './component/utils';

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <HandleRedirect />
      <Router />
      <ToastContainer autoClose={2000} position="bottom-right" />
    </QueryClientProvider>
  );
}

export default App;
