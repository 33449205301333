import { useMutation, useQuery } from 'react-query';
import {
  approveDocument,
  confirmDocument,
  doLogin,
  getAdminUserDetails,
  getAllAdminRequests,
  getDocForApproval,
  getDocForPreview,
  getSignedDocument,
  getUnsignedDocument,
  insertSignature,
  markDocumentSigned,
  sendOTP,
  verifyOtp,
} from '../api';
import { queryClient } from './Client';

export const useDoLogin = () => {
  return useMutation({
    mutationFn: doLogin,
    mutationKey: 'login',
    onSuccess: () => {
      queryClient.clear();
    },
  });
};

export const useGetDocumentForSigning = () => {
  return useQuery({
    queryKey: 'unignedDoc',
    queryFn: getUnsignedDocument,
  });
};

export const useAttachSignature = () => {
  return useMutation({
    mutationFn: insertSignature,
    mutationKey: 'attachSignature',
  });
};

export const useMarkDocumentSigned = () => {
  return useMutation({
    mutationFn: markDocumentSigned,
    mutationKey: 'markDocumentSign',
  });
};

export const useGetAdminUserDetails = () => {
  return useQuery({
    queryKey: 'adminUser',
    queryFn: getAdminUserDetails,
  });
};

export const useGetDocumentsForPreview = () => {
  return useQuery({
    queryKey: 'previewDocument',
    queryFn: getDocForPreview,
  });
};

export const useSendOTP = () => {
  return useMutation({
    mutationFn: sendOTP,
    mutationKey: 'sendOTP',
  });
};

export const useVerifyOtp = () => {
  return useMutation({
    mutationFn: verifyOtp,
    mutationKey: 'verifyOTP',
  });
};

export const useGetDocForApproval = () => {
  return useQuery({
    queryKey: 'for_approvalDOc',
    queryFn: getDocForApproval,
  });
};

export const useApproveDocument = () => {
  return useMutation({
    mutationKey: 'approveDoc',
    mutationFn: approveDocument,
    onSuccess: () => {
      queryClient.invalidateQueries(['for_approvalDOc']);
      queryClient.refetchQueries(['for_approvalDOc']);
    },
  });
};

export const useConfirmDocument = () => {
  return useMutation({
    mutationKey: 'confirmDoc',
    mutationFn: confirmDocument,
  });
};

export const  useGetAllAdminRequests = () => {
  return useQuery({
    queryKey: 'allAdminRequests',
    queryFn: getAllAdminRequests,
  });
}

export const useGetSignedDocument = () => {
  return useQuery({
    queryKey: 'signedDoc',
    queryFn: getSignedDocument,
  });
};