/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import LazyPdfDocument from '../../component/lazyPdfDocument';
import { Dialog } from '@mui/material';
import { Icon } from '@iconify/react';
import {
  useAttachSignature,
  useGetDocumentForSigning,
  useMarkDocumentSigned,
} from '../../queries/queries';
import { DocMetaData } from '../../api';
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
  b64toBlob,
  convertToBase64,
} from '../../component/utils';
import SignaturePad from '../../component/SignPad';
import { useSignaturePadDialog } from '../../store/SingatureStore';
import { toast } from 'react-toastify';
import { Outlet, useNavigate } from 'react-router';
import useAuthStore from '../../store/AuthStore';
import { ProfileDropDown, ProfileImage } from '../../component/Profile';
import { Coordinates, NewDocMetaData } from '../../types/doc';
type SkeletonLoaderProps = {
  className?: string;
};
const Dashboard = () => {
  const A4_RATIO = 297 / 210;
  const { state: showSignaturePad, setState: setShowSignaturePad } =
    useSignaturePadDialog();
  const navigate = useNavigate();
  const {
    data: unsignedDocument,
    isLoading: isDocumentDetailsLoading,
    refetch: refetchDocData,
  } = useGetDocumentForSigning();
  const { mutate: markDocAsSigned, isLoading: confirmingSignature } =
    useMarkDocumentSigned();
  const [signedPdfUrl, setSignedPdfUrl] = useState<string>('');
  const { mutate: signDocument, isLoading: signatureBeingAttached } =
    useAttachSignature();
  const [signatureStatus, setSignatureStatus] = useState<string>('');
  const [isDocumentSigned, setIsDocumentSigned] = useState(false);
  const [documentMetaData, setDocumentMetaData] = useState<NewDocMetaData>();
  const [wetSignature, setWetSignature] = useState('');
  const [signatruePageDownloaded, setSignaturePageDownloaded] = useState(false);
  const { user } = useAuthStore();
  useEffect(() => {
    if (unsignedDocument?.signStatus?.toLowerCase() === 'cancelled') {
      toast('Document signing has been cancelled', {
        autoClose: 2000,
        type: 'error',
      });
      setSignatureStatus('cancelled');
    }
    if (unsignedDocument)
      setDocumentMetaData({ ...documentMetaData, ...unsignedDocument });
  }, [unsignedDocument]);

  const downloadSignaturePage = () => {
    b64toBlob(
      documentMetaData?.wetSignatureTemplate || '',
      'application/pdf',
      `${user?.full_name}_signature.pdf`,
    ).then(() => {
      setSignaturePageDownloaded(true);
    });
  };

  // function createDivsFromCoordinates(el: any, coordinates: Coordinates, page: string) {
  //   const div = document.createElement('div');
  //   const llx = coordinates[page].llx;
  //   const lly = coordinates[page].lly;
  //   const urx = coordinates[page].urx;
  //   const ury = coordinates[page].ury;

  //   const width = urx; // Width of the article
  //   const height = ury - lly; // Height from bottom to upper boundary

  //   div.style.position = 'absolute';
  //   div.style.left = `${llx}px`; // Margin from left
  //   div.style.bottom = `${lly}px`; // Margin from bottom of rectangle
  //   div.style.width = `${width}px`;
  //   div.style.height = `${height}px`;
  //   div.style.border = '1px solid red';

  //   el.appendChild(div);
  // }
  function calculateRelativePosition(
    llx: number,
    lly: number,
    urx: number,
    ury: number,
    parentRect: DOMRect,
  ) {
    const width = urx - llx;
    const height = lly - ury;
    const relativeLeft = parentRect.left + llx;
    const relativeTop = parentRect.height - parentRect.top - height;
    const relativeBottom = parentRect.bottom - lly;
    const relativeRight = urx;

    return {
      relativeLeft: relativeLeft,
      relativeBottom: relativeBottom,
      relativeRight: relativeRight,
      relativeTop: relativeTop,
      width: width,
      height: height,
    };
  }
  const handleImgLoad = (event: any, page: any) => {
    console.log(page);
    if (documentMetaData?.signatureType !== 'esign') {
      return;
    }
    const img = event.target;
    const parent = img.parentElement;
    const { llx, lly, urx, ury } = documentMetaData.coordinates[page];
    console.log(llx, lly, urx, ury);
    const { relativeLeft, relativeBottom, relativeRight, relativeTop } =
      calculateRelativePosition(
        llx,
        lly,
        urx,
        ury,
        parent.getBoundingClientRect(),
      );

    console.log(relativeLeft, relativeBottom, relativeRight, relativeTop, page);
    const div = document.createElement('div');
    const canvas = document.createElement('canvas');
    canvas.width = relativeRight - relativeLeft;
    canvas.height = relativeTop - relativeBottom;
    canvas.style.position = 'absolute';
    canvas.style.left = `${relativeLeft}px`;
    canvas.style.bottom = `${relativeBottom}px`;
    canvas.style.width = `${canvas.width}px`;
    canvas.style.height = `${canvas.height}px`;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.fillStyle = 'red';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }
    parent.appendChild(canvas);
    div.style.position = 'absolute';
    div.style.left = `${relativeLeft}px`;
    div.style.bottom = `${relativeBottom}px`;
    div.style.width = `210px`;
    div.style.height = `60px`;
    div.style.backgroundColor = '#D8EFD3';
    div.innerHTML = `<span class="text-xs font-semibold">Sign Here</span>`;
    parent.appendChild(div);
  };

  const handlePdfUploadAndConvertTOBase64 = (e: any) => {
    const file = e.target.files[0];
    if (!file || !file?.name?.endsWith('.pdf')) {
      toast('Please upload a pdf file', {
        type: 'error',
        autoClose: 2000,
      });
      return;
    } else {
      convertToBase64(file).then((base64: any) => {
        setWetSignature(base64);
        signDocument(String(base64), {
          onSuccess: (data) => {
            toast('Document signed', { type: 'success', autoClose: 2000 });
            console.log(data);
            setSignedPdfUrl(data.message);
            setIsDocumentSigned(true);
          },
          onError: (err) => {
            toast('Error Occurred while signing', {
              type: 'error',
              autoClose: 2000,
            });
          },
        });
      });
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const dataURL = e.target?.result;
      const base64 = dataURL?.toString().split(',')[1];
      console.log(base64);
    };
  };

  const unsignedPdfUrls = useMemo(() => {
    return documentMetaData?.document || [];
  }, [documentMetaData]);
  useEffect(() => {
    if (!showSignaturePad.open && showSignaturePad.signature) {
      let signature = showSignaturePad.signature;
      if (signature.startsWith('data:')) {
        signature = signature.replace('data:', '').replace(/^.+,/, '');
      }
      signDocument(signature, {
        onSuccess: (data) => {
          toast('Document signed', { type: 'success', autoClose: 2000 });
          console.log(data);
          setSignedPdfUrl(data.message);
          // setDocumentMetaData({ ...documentMetaData, documentUrl: data.pdfKey })
          setIsDocumentSigned(true);
        },
        onError: (err) => {
          toast('Error Occurred while signing', {
            type: 'error',
            autoClose: 2000,
          });
        },
      });
    }
  }, [showSignaturePad.open, showSignaturePad.signature]);

  function sign() {
    if (documentMetaData?.signatureType === 'wet_signature') {
      toast('Please upload a signed pdf page', {
        type: 'info',
        autoClose: 2000,
      });
      const inputElement = document.createElement('input');
      inputElement.type = 'file';
      inputElement.accept = '.pdf';
      inputElement.onchange = handlePdfUploadAndConvertTOBase64;
      inputElement.click();
      return;
    }
    setShowSignaturePad({ ...showSignaturePad, open: true, signature: '' });
  }
  const resetSignature = () => {
    refetchDocData();
    setIsDocumentSigned(false);
    setShowSignaturePad({ ...showSignaturePad, signature: undefined });
  };
  function confirmSignature() {
    let signature = showSignaturePad.signature || '';
    if (documentMetaData?.signatureType === 'wet_signature') {
      signature = wetSignature;
    }
    if (signature.startsWith('data:')) {
      signature = signature.replace('data:', '').replace(/^.+,/, '');
    }
    markDocAsSigned(signature, {
      onSuccess: () => {
        navigate('/success');
      },
      onError: () => {
        toast('Error occurred while marking document as signed', {
          type: 'error',
          autoClose: 2000,
        });
      },
    });
  }

  return (
    <div className="flex h-[calc(100vh-15vh)] justify-center gap-4">
      <VStack
        onContextMenu={(e) => e.preventDefault()}
        onClick={(e) => e.preventDefault()}
        className="shadow-lg p-4 rounded-md bg-gray-400 relative overflow-auto w-2/3 cursor-not-allowed opacity-95"
      >
        {isDocumentDetailsLoading ? (
          <SkeletonLoader className="h-[calc(100vh-20vh)] w-[calc(100vw-60vw)]" />
        ) : isDocumentSigned ? (
          <LazyPdfDocument
            url={signedPdfUrl}
            key={'signedPdf'}
            name="Signed Document"
          />
        ) : (
          <VStack className="border-b-4 ">
            {unsignedPdfUrls.map((url, index) => {
              return (
                <HStack id={`page_${url.page}`}>
                  <img
                    src={url.image}
                    alt={url.page?.toString()}
                    onLoad={(e) => handleImgLoad(e, url.page)}
                    // style={{
                    //   width: '100%',
                    //   height: 'auto',
                    // }}
                  />
                </HStack>
              );
            })}
          </VStack>
        )}
      </VStack>
      <VStack className="w-1/3 p-4 bg-gray-100 rounded-md flex flex-col m-2">
        <h2 className="text-lg font-semibold mb-2 underline text-center">
          Document Information
        </h2>
        <hr />
        {isDocumentDetailsLoading ? (
          <>
            <SkeletonLoader className="h-6 w-3/4 mt-2" />
            <hr />
            <SkeletonLoader className="h-6 w-3/4 mt-2" />
            <hr />
            <SkeletonLoader className="h-6 w-3/4 mt-2" />
            <hr />
            <SkeletonLoader className="h-6 w-3/4 mt-2" />
            <hr />
            <SkeletonLoader className="h-6 w-3/4 mt-2" />
            <hr />
            <SkeletonLoader className="h-6 w-3/4 mt-2" />
            <hr />
          </>
        ) : (
          <>
            <div className="mt-2 justify-between text-sm flex">
              <span className="text-start flex-1">User Name</span>
              <span className="text-blue-500 text-right">
                {user?.full_name}
              </span>
            </div>
            <hr />
            <div className="mt-2 justify-between text-sm flex">
              <span className="text-start flex-1">Organization</span>
              <span className="text-blue-500 text-right">
                {documentMetaData?.companyName}
              </span>
            </div>
            <hr />
            <div className="mt-2 justify-between text-sm flex">
              <span className="text-start flex-1">Document Name</span>
              <span className="text-blue-500 text-right">
                {documentMetaData?.documentName}
              </span>
            </div>
            <hr />
            <div className="mt-2 justify-between text-sm flex">
              <span className="text-start flex-1">Requested By</span>
              <span className="text-blue-500 text-right">
                {documentMetaData?.requestedBy}
              </span>
            </div>
            <hr />
            <div className="mt-2 justify-between text-sm flex">
              <span className="text-start flex-1">Requested On</span>
              <span className="text-blue-500 text-right">
                {new Date(documentMetaData?.createdAt || '')?.toDateString()}
              </span>
            </div>
            <hr />
            <div className="mt-2 justify-between text-sm flex">
              <span className="text-start flex-1">Reason</span>
              <span className="text-blue-500 text-right">
                {documentMetaData?.reason}
              </span>
            </div>
            <hr />
            <div className="mt-2 justify-between text-sm flex">
              <span className="text-start flex-1">
                Requested Signature Method
              </span>
              <span className="text-blue-500 text-right">
                {documentMetaData?.signatureType === 'esign'
                  ? 'E-Sign'
                  : 'Wet Sign'}
              </span>
            </div>
            <hr />
            {signatureStatus !== 'cancelled' &&
              documentMetaData?.signatureType === 'wet_signature' && (
                <div className="mt-2 justify-end text-sm flex flex-col gap-2 align-middle">
                  <span className="text-xxs">Download Signature page</span>
                  <button
                    className="bg-gray-300 h-16 w-16 rounded-full justify-center text-center align-middle"
                    onClick={() => downloadSignaturePage()}
                  >
                    <Icon
                      icon="line-md:download-loop"
                      className="mx-auto"
                      width="24"
                    />
                  </button>
                </div>
              )}
          </>
        )}
        {signatureStatus !== 'cancelled' && (
          <div className="flex justify-center mt-4 flex-col">
            <span className="text-xxs pb-2">
              By continuing, I agree to do electronic sign from this platform.
            </span>
            {!isDocumentSigned && (
              <ButtonPrimary
                className="flex justify-center"
                disabled={
                  documentMetaData?.signatureType === 'wet_signature' &&
                  !signatruePageDownloaded
                }
                onClick={() => sign()}
              >
                {signatureBeingAttached ? (
                  <Icon
                    className="animate-spin text-center"
                    icon="lucide:loader-2"
                    width="24"
                  />
                ) : documentMetaData?.signatureType === 'wet_signature' ? (
                  'Upload Signature Page'
                ) : (
                  'Proceed to Sign'
                )}
              </ButtonPrimary>
            )}
            {isDocumentSigned && (
              <div className="flex flex-col gap-2">
                <ButtonPrimary
                  className="flex justify-center"
                  onClick={() => confirmSignature()}
                >
                  {signatureBeingAttached || confirmingSignature ? (
                    <Icon
                      className="animate-spin text-center"
                      icon="lucide:loader-2"
                      width="24"
                    />
                  ) : (
                    'Confirm'
                  )}
                </ButtonPrimary>
                <ButtonPrimary1
                  className="flex justify-center"
                  onClick={() => {
                    resetSignature();
                  }}
                >
                  {signatureBeingAttached ? (
                    <Icon
                      className="animate-spin text-center"
                      icon="lucide:loader-2"
                      width="24"
                    />
                  ) : (
                    'Re-Sign'
                  )}
                </ButtonPrimary1>
              </div>
            )}
          </div>
        )}
      </VStack>
      <Dialog open={showSignaturePad.open} maxWidth="xl">
        <SignaturePad />
      </Dialog>
    </div>
  );
};

export default Dashboard;
const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ className }) => (
  <div className={`animate-pulse bg-gray-300 rounded ${className}`}></div>
);
