function SignCancelled() {
    window.history.pushState(null, '', null);
    return (
        <div className="w-screen mx-auto">
        <div className="flex items-center justify-center min-h-screen bg-gray-200">
          <div className="bg-white rounded-lg shadow-lg max-w-sm w-full">
            <div className="bg-orange-500 rounded-t-lg p-8 text-center">
              <h2 className="text-white text-lg font-semibold">
              Uh-Oh. The sign request has been cancelled.
              </h2>
            </div>
            <div className="p-8 text-center">
              <p className="text-gray-700 mb-6">
                
              </p>
              <p className="text-orange-501 mb-6">
              {' '}
              You may close the window now.
               </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default  SignCancelled;
  