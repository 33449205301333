import React, { useState, lazy, useRef, useMemo, useEffect } from 'react';
import { Document, Outline, Page, pdfjs } from 'react-pdf';
import { PDFDocumentComponentPropTypes } from './lazyPdfDocument';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Icon } from '@iconify/react';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const PdfDocument: React.FC<PDFDocumentComponentPropTypes> = ({
  url,
  name,
}) => {
  const [numPages, setNumPages] = useState<number>();
  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }
  const [renderMode, setRenderMode] = useState<'canvas' | 'svg'>('canvas');
  const [debugMenu, setDebugMenu] = useState(false);
  const parentRef = React.useRef<HTMLDivElement>(null);
  const options = useMemo(() => {
    return {
      standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
      cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
      cMapPacked: true,
    };
  }, []);
  return (
    <div
      key={`${url}-div`}
      onContextMenu={(e) => {
        if (e.ctrlKey && e.shiftKey && e.altKey) {
          setDebugMenu(!debugMenu);
        }
        e.preventDefault();
      }}
      ref={parentRef}
      className="w-auto overflow-auto"
    >
      <Document
        file={url}
        key={`${url}-div`}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
        renderMode={renderMode}
        className={`w-full`}
        loading={
          <Icon
            icon="eos-icons:three-dots-loading"
            width={72}
            color="#A7A9B8"
          />
        }
      >
        {Array.from(new Array(numPages), (el, index) => (
          <>
            <Page
              renderMode={renderMode}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={parentRef.current?.clientWidth}
            />
            <div
              key={`page_${index + 1}_break`}
              className="w-full border-t-4 border-dashed border-gray"
            ></div>
          </>
        ))}
      </Document>
    </div>
  );
};

export default PdfDocument;
