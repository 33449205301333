import createStore from 'zustand';

interface ShowSignaturePadDialog {
  open: boolean;
  ids?: string[];
  entityType?: string;
  signatureType?: string;
  signature?: string;
  isLoading?: boolean;
}
interface StateStore<T> {
  state: T;
  setState: (state: T | ((state?: T) => T)) => void;
}
export const useSignaturePadDialog = createStore<
  StateStore<ShowSignaturePadDialog>
>((set) => ({
  state: { open: false },
  setState: (input) => {
    if (typeof input === 'function') {
      return set((store) => ({ ...store, state: input(store.state) }));
    } else {
      return set((store) => ({ ...store, state: input }));
    }
  },
}));
