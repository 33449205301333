/* eslint-disable jsx-a11y/anchor-is-valid */
import { Icon } from '@iconify/react';
import useAuthStore from '../store/AuthStore';
import { useEffect, useState } from 'react';
import { queryClient } from '../queries/Client';
import React from 'react';
import { usePreAuthorizedTokenStore } from '../store/PreAuthrizedTokenStore';

export function ProfileDropDown({
  className = 'items-end',
  render = () => (
    <Icon
      icon="carbon:overflow-menu-horizontal"
      className="text-gray-400 rounded hover:bg-gray-300"
      height={20}
    />
  ),
  navigate,
}: {
  className?: string;
  // eslint-disable-next-line no-undef
  render?: () => JSX.Element;
  navigate: Function;
}) {
  const { clear,user } = useAuthStore();
  const { clear: clearToken } = usePreAuthorizedTokenStore();
  const [showModal, setShowModal] = useState(false);
  const [isDisabled,setIsDisabled] = useState(false)
  useEffect(()=>{
    if(user?.type ==="signer"){
      setIsDisabled(true)
    }
  },[user])
  function logout() {
    localStorage.removeItem('companyId');
    localStorage.removeItem('hissaUrl');
    queryClient.clear();
    clear();
    clearToken();
  }
  const RenderElement = render;
  const contextRef = React.useRef<HTMLDivElement>(null);
  return (
    <div className="inline-flex">
      <button
        className={`inline-flex text-xs text-center rounded-lg peer ${isDisabled?"cursor-not-allowed":""}`}
        type="button"
        onClick={() => setShowModal((state) => !state)}
        disabled={isDisabled}
      >
        <RenderElement />
      </button>
      {showModal && (
        <div
          ref={contextRef}
          id="dropdown"
          className="absolute z-10 mt-8 bg-white border divide-y divide-gray-100 rounded shadow right-10"
        >
          <ul
            className="py-1 text-xs font-normal divide-y text-dark w-36"
            aria-labelledby="dropdownDefault"
          >
            <li>
              <a
                className={` block px-4 py-2 hover:bg-gray-100 `}
                onMouseDown={(e) => {
                  e.preventDefault();
                  setShowModal((state) => !state);
                  logout();
                }}
              >
                Logout
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export function ProfileImage(props: { name: string; picture?: string }) {
  const { name, picture } = props;
  const [validImage, setValidImage] = useState(!!picture);
  React.useEffect(() => {
    if (picture)
      fetch(picture).catch((err) => {
        setValidImage(false);
      });
  }, [picture]);

  return (
    <div>
      {picture && validImage ? (
        <img
          className="w-10 mt-1 border-2 border-white rounded-md"
          alt=""
          src={picture}
        />
      ) : (
        <div className="mt-2 uppercase profileImage">{name?.charAt(0)}</div>
      )}
    </div>
  );
}
